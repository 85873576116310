section{
    .banner-contacto{
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorMain;
        img{
            width: 80%;
        }
    }
    .contacto-info{
        width: 100%;
        height: auto;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}    

@media only screen and (min-width: 1000px){
    section{
        .banner-contacto{
            img{
                width: 500px;
            }
        }
    }               
}