footer{
	width: 100%;
	height: auto;
	.container{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.container-links{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 30px;
			background-color: $colorYellow;
			.links{
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				text-align: center;
				.button{
					width: auto;
					background-color: transparent;
					border: none;
					cursor: pointer;
					font-size: 16px;
					line-height: 36px;
					padding: 10px;
					margin: 10px 30px;
				}
				.button:hover{
					background-color: $colorMain;
					color: $colorYellow;
					transition: 0.2s;
				}
			}
		}
		.container-logo{
			width: 100%;
			display: flex;
			justify-content: center;
			padding: 0px 0px 30px 0px;
			background-color: $colorYellow;
			img{
				max-width: 140px;
			}
		}
	}
}

