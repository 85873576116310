section{
    .banner-home{
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        .container{
            width: 300px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            img{
                margin: 50px;
                max-width: 300px;
            }
            .btnB{
                margin-bottom: 100px;
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    .banner-home{
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}