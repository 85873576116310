@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  color: #333;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  background-color: #fff;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

article {
  width: 90%;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  padding: 30px 10px;
}

h1 {
  font-size: 22px;
  text-align: center;
  padding: 5px;
}

h2 {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  padding: 5px;
}

h3 {
  font-size: 26px;
  text-align: center;
}

h4 {
  font-size: 22px;
  text-align: center;
}

.btnB {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 18px;
  color: #fff;
}

.btnB:hover {
  background-color: #fff;
  color: #333;
  transition: 0.2s;
  cursor: pointer;
}

.btnN {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid #333;
  font-size: 18px;
  color: #333;
}

.btnN:hover {
  background-color: #333;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

.btnNSB {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 18px;
  color: #333;
  margin: 20px;
}

.btnNSB:hover {
  color: #999;
  transition: 0.3s;
  cursor: pointer;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0px;
}
.loading h2 {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 30px;
}
.loading img {
  width: 200px;
}

.notA404 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
}
.notA404 h4 {
  color: #fff;
  margin: 30px;
}
.notA404 div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.notA404 div img {
  max-width: 80%;
}

nav {
  width: 100%;
  height: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 100;
  margin-top: 10px;
}
nav svg {
  fill: #333;
  width: 40px;
  padding: 5px;
}
nav svg:hover {
  cursor: pointer;
}
nav .navbar {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
nav .navbar .navbar-buttons {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .navbar .navbar-buttons .logo {
  max-height: 110px;
  max-width: 100%;
}
nav .navbar .navbar-buttons .svg {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
}
nav .navbar .navbar-buttons svg {
  width: 40px;
  margin: 10px;
}
nav .navbar .navbar-buttons .brand {
  font-size: 56px;
  font-weight: 900;
}
nav .navbar .navbar-home-menu {
  display: none;
}
nav .buttons-open {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #c32472;
  margin-top: 140px;
  padding: 50px 0px;
}
nav .buttons-open .button {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 18px;
  padding: 20px;
  color: #fff;
}
nav .buttons-open .button:hover {
  background-color: #edd150;
  color: #333;
}
nav .buttons-open .social {
  width: 90%;
  height: auto;
  margin-top: 20px;
  text-align: center;
}
nav .buttons-open .social svg {
  width: 40px;
  margin: 0px 10px;
}
nav .buttons-open .social svg:hover {
  fill: #999;
}
nav .buttons-closed {
  display: none;
}

@media only screen and (min-width: 1024px) {
  nav .navbar .navbar-home-menu {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav .navbar .navbar-home-menu .button {
    width: auto;
    height: auto;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    margin: 10px 50px;
  }
  nav .navbar .navbar-home-menu .button:hover {
    background-color: #68b8a5;
    color: #fff;
  }
  nav .buttons-open .button {
    text-align: left;
  }
  nav .buttons-open .social {
    text-align: left;
  }
  nav .buttons-open .social svg {
    margin: 0px;
    margin-right: 20px;
  }
}
section .banner-home {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
section .banner-home .container {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
section .banner-home .container img {
  margin: 50px;
  max-width: 300px;
}
section .banner-home .container .btnB {
  margin-bottom: 100px;
}

@media only screen and (min-width: 1024px) {
  .banner-home {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
section .intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}
section .intro article {
  text-align: left;
}
section .intro article .span1 {
  background-color: #edd150;
  padding: 4px;
  font-weight: 600;
}
section .intro article .span2 {
  background-color: #c32472;
  color: #fff;
  padding: 4px;
  font-weight: 600;
}
section .intro article .span3 {
  background-color: #68b8a5;
  color: #fff;
  padding: 4px;
  font-weight: 600;
}

@media only screen and (min-width: 1000px) {
  section .intro h2 {
    font-size: 40px;
  }
}
section .clientes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section .hacemos {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
section .hacemos .container {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section .hacemos .bkg-magenta {
  background-color: #c32472;
}
section .hacemos .bkg-cyan {
  background-color: #68b8a5;
}
section .hacemos .bkg-yellow {
  background-color: #edd150;
}
section .hacemos .bkg-red {
  background-color: #b03a44;
}

@media only screen and (min-width: 1000px) {
  section .hacemos .container {
    width: 33.33%;
  }
}
section .mkt-externo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section .mkt-externo .banner {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
section .mkt-externo h3 {
  background-color: #b03a44;
  padding: 5px;
  color: #fff;
  margin-top: 30px;
}

section .mkt-digital {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section .mkt-digital .banner {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
section .mkt-digital .banner h2 {
  background-color: #68b8a5;
  padding: 5px 10px;
  color: #fff;
  margin-top: 30px;
}
section .mkt-digital article .banner {
  width: 100%;
  height: 300px;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: initial;
  margin-bottom: 20px;
}
section .mkt-digital .span1 {
  background-color: #edd150;
  padding: 4px;
  font-weight: 600;
  font-size: 24px;
}
section .mkt-digital .span2 {
  background-color: #c32472;
  color: #fff;
  padding: 4px;
  font-weight: 600;
  font-size: 24px;
}
section .mkt-digital .span3 {
  background-color: #68b8a5;
  color: #fff;
  padding: 4px;
  font-weight: 600;
  font-size: 24px;
}
section .mkt-digital .span4 {
  background-color: #b03a44;
  color: #fff;
  padding: 4px;
  font-weight: 600;
  font-size: 24px;
}

.clientes-card {
  width: 140px;
  height: 140px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.clientes-card .img {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

@media only screen and (min-width: 1000px) {
  .img:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
  }
}
.swiper-container {
  width: 100vw;
  height: auto;
  display: flex;
}
.swiper-container .swiper-slide {
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.swiper-container .swiper-pagination {
  display: none;
}
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  display: none;
}

section .banner-contacto {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
}
section .banner-contacto img {
  width: 80%;
}
section .contacto-info {
  width: 100%;
  height: auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1000px) {
  section .banner-contacto img {
    width: 500px;
  }
}
section .contacto {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 75px 50px;
}
section .contacto h3 {
  text-align: left;
  margin: 10px 0px;
}
section .contacto .container-form {
  padding: 20px;
  order: 2;
}
section .contacto .container-form form {
  width: 300px;
}
section .contacto .container-form form input, section .contacto .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #eaeaea;
  margin: 5px 0px;
  font-size: 16px;
}
section .contacto .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .contacto .container-form form p {
  color: #c32472;
  margin-bottom: 20px;
}
section .contacto .container-form form .btnN {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid #333;
  font-size: 18px;
  color: #333;
  margin-top: 10px;
}
section .contacto .container-form form .btnN:hover {
  background-color: #333;
  color: #fff;
  transition: 0.9s;
  cursor: pointer;
}
section .contacto .container-img {
  width: 500px;
  margin-left: 0;
  order: 1;
}
section .contacto .container-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1000px) {
  section .contacto .container-form {
    order: 1;
  }
  section .contacto .container-form form {
    width: 500px;
  }
  section .contacto .container-img {
    order: 2;
    margin-left: 50px;
  }
  section .contacto .container-img img {
    max-width: 100%;
  }
}
.obra {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.obra .container-obra {
  width: 80%;
  height: auto;
  margin: 100px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.obra .container-obra h2 {
  font-size: 34px;
}
.obra .container-obra h4 {
  font-size: 14px;
}
.obra .container-obra form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}
.obra .container-obra form article {
  width: 100%;
  height: auto;
  margin: 5px;
  font-size: 16px;
}
.obra .container-obra form .fecha-form {
  width: 62px;
  height: auto;
  border: none;
  margin-left: 6px;
  font-size: 14px;
}
.obra .container-obra form .input-form {
  width: 100%;
  margin: 10px 0px 20px 0px;
  border: none;
  border-bottom: 1px solid #333;
  font-size: 16px;
  font-weight: 500;
}
.obra .container-obra form p {
  color: red;
  font-weight: 600;
}
.obra .container-obra form .sigCanvas {
  width: 330px;
  height: 200px;
  border: 1px solid #333;
}
.obra .container-obra form .container-btn-canvas {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.obra .container-obra form .container-btn-canvas .btnN {
  margin: 15px;
  font-size: 16px;
  font-weight: 500;
}

.container-obra-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.container-obra-detalle h2 {
  font-size: 36px;
  margin: 10px;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
}
.container-obra-detalle table {
  width: 700px;
  text-align: center;
}
.container-obra-detalle table .w100 {
  width: 100%;
}
.container-obra-detalle table tr {
  width: 100%;
  height: auto;
}
.container-obra-detalle table tr span {
  font-weight: 500;
}
.container-obra-detalle table tr td {
  padding: 5px 10px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.container-obra-detalle table tr td img {
  min-height: 200px;
  max-height: 200px;
  margin: 30px 0px;
}

@media only screen and (min-width: 1000px) {
  .obra {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .obra .container-obra {
    width: 500px;
    height: auto;
    margin: 50px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .obra .container-obra h2 {
    font-size: 34px;
  }
  .obra .container-obra h4 {
    font-size: 14px;
  }
  .obra .container-obra form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }
  .obra .container-obra form article {
    width: 100%;
    height: auto;
    margin: 20px;
    font-size: 16px;
  }
  .obra .container-obra form .fecha-form {
    width: 62px;
    height: auto;
    border: none;
    margin-left: 6px;
    font-size: 14px;
  }
  .obra .container-obra form .input-form {
    width: 100%;
    margin: 10px 0px 20px 0px;
    border: none;
    border-bottom: 1px solid #333;
    font-size: 16px;
    font-weight: 500;
  }
}
footer {
  width: 100%;
  height: auto;
}
footer .container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer .container .container-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #edd150;
}
footer .container .container-links .links {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
footer .container .container-links .links .button {
  width: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 36px;
  padding: 10px;
  margin: 10px 30px;
}
footer .container .container-links .links .button:hover {
  background-color: #333;
  color: #edd150;
  transition: 0.2s;
}
footer .container .container-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 0px 30px 0px;
  background-color: #edd150;
}
footer .container .container-logo img {
  max-width: 140px;
}

@media only screen and (min-width: 1000px) {
  article {
    width: 60%;
  }
}/*# sourceMappingURL=styles.css.map */