nav{
  width: 100%;
  height: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: $colorWhite;
  z-index: 100;
  margin-top: 10px;
  svg{
    fill: $colorMain;
    width: 40px;
    padding: 5px;
  }
  svg:hover{
    cursor: pointer;
  }
  .navbar{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    background-color: $colorWhite;
    .navbar-buttons{
      width: 100%;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo{
        max-height: 110px;
        max-width: 100%;
      }
      .svg{
        width: auto;
        height: auto;
        background-color: transparent;
        border: none;
      }
      svg{
        width: 40px;
        margin: 10px;
      }
      .brand{
        font-size: 56px;
        font-weight: 900;
      }      
    }
    .navbar-home-menu{
      display: none;
    }
    
  }
  .buttons-open{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $colorMagenta;
      margin-top: 140px;
      padding: 50px 0px;
      .button{
        width: auto;
        height: auto;
        text-align: center;
        font-size: 18px;
        padding: 20px;
        color: $colorWhite;
      }
      .button:hover{
        background-color: $colorYellow;
        color: $colorMain;
      }
      .social{
        width: 90%;
        height: auto;
        margin-top: 20px;
        text-align: center;
        svg{
          width: 40px;
          margin: 0px 10px;
        }
        svg:hover{
          fill: $colorSecondary;
        }
      }
  }
  .buttons-closed{
    display: none;
  }
}

@media only screen and (min-width: 1024px){
  nav{
    .navbar{
      .navbar-home-menu{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .button{
          width: auto;
          height: auto;
          text-align: center;
          font-size: 18px;
          padding: 10px;
          margin: 10px 50px;
        }
        .button:hover{
          background-color: $colorCyan;
          color: $colorWhite;
        }
      }      
    }
    .buttons-open{
      .button{
        text-align: left;
      }
      .social{
        text-align: left;
        svg{
          margin: 0px;
          margin-right: 20px;
        }
      }
  }
  }
}