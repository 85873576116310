section{
    .mkt-externo{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .banner{
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
        }
        h3{
            background-color: $colorRed;
            padding: 5px;
            color: $colorWhite;
            margin-top: 30px;
        }
    }
}

@media only screen and (min-width: 1000px){
}