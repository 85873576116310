section{
    .contacto{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: $colorWhite;
        padding: 75px 50px;
        h3{
            text-align: left;
            margin: 10px 0px;
        }
        .container-form{
            padding: 20px;
            order: 2;
            form{
                width: 300px;
                input, textarea{
                    width: 100%;
                    padding: 10px;
                    border: 1px solid $colorGray;
                    margin: 5px 0px;
                    font-size: 16px;
                }
                textarea{
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100px;
                    max-height: 400px;
                }
                p{
                    color: $colorMagenta;
                    margin-bottom: 20px;
                }
                .btnN{
                    width: auto;
                    height: auto;
                    padding: 10px 30px;
                    background-color: transparent;
                    border: 1px solid $colorMain;
                    font-size: 18px;
                    color: $colorMain;
                    margin-top: 10px;
                }
                .btnN:hover{
                    background-color: $colorMain;
                    color: $colorWhite;
                    transition: 0.9s;
                    cursor: pointer;
                }
            }
        }
        .container-img{
            width: 500px;
            margin-left: 0;
            order: 1;
            img{
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .contacto{
            .container-form{
                order: 1;
                form{
                    width: 500px;
                }
            }
            .container-img{
                order: 2;
                margin-left: 50px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
}