section{
    .mkt-digital{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .banner{
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
            h2{
                background-color: $colorCyan;
                padding: 5px 10px;
                color: $colorWhite;
                margin-top: 30px;
            }
        }
        article{
            .banner{
                width: 100%;
                height: 300px;
                justify-content: center;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-attachment: initial;
                margin-bottom: 20px;
            }
        }
        .span1{
            background-color: $colorYellow;
            padding: 4px;
            font-weight: 600;
            font-size: 24px;
        }
        .span2{
            background-color: $colorMagenta;
            color: #fff;
            padding: 4px;
            font-weight: 600;
            font-size: 24px;
        }
        .span3{
            background-color: $colorCyan;
            color: #fff;
            padding: 4px;
            font-weight: 600;
            font-size: 24px;
        }
        .span4{
            background-color: $colorRed;
            color: #fff;
            padding: 4px;
            font-weight: 600;
            font-size: 24px;
        }
    }
}

@media only screen and (min-width: 1000px){
}