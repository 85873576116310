@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


$colorMain: #333;
$colorSecondary: #999;
$colorYellow: #edd150;
$colorCyan: #68b8a5;
$colorMagenta: #c32472;
$colorRed: #b03a44;
$colorWhite: #fff;
$colorGray: #eaeaea;

*{
	padding: 0;
	margin: 0;
	font-family: "Roboto Condensed", sans-serif;
	font-weight: 400;
	color: $colorMain;
	text-decoration: none;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body{
	width: 100%;
	background-color: $colorWhite;
}

.appContainer{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main{
	flex-grow: 1;
}

section{
	width: 100%;
	max-width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

article{
	width: 90%;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	padding: 30px 10px;
}

h1{
	font-size: 22px;
    text-align: center;
	padding: 5px;
}

h2{
	font-size: 36px;
    text-align: center;
	font-weight: 600;
	padding: 5px;
}
h3{
	font-size: 26px;
	text-align: center;
}
h4{
	font-size: 22px;
	text-align: center;
}

.btnB{
	width: auto;
	height: auto;
	padding: 10px 30px;
	background-color: transparent;
	border: 1px solid $colorWhite;
	font-size: 18px;
	color: $colorWhite;
}
.btnB:hover{
	background-color: $colorWhite;
	color: $colorMain;
	transition: 0.2s;
	cursor: pointer;
}

.btnN{
	width: auto;
	height: auto;
	padding: 10px 30px;
	background-color: transparent;
	border: 1px solid $colorMain;
	font-size: 18px;
	color: $colorMain;
}
.btnN:hover{
	background-color: $colorMain;
	color: $colorWhite;
	transition: 0.2s;
	cursor: pointer;
}

.btnNSB{
	width: auto;
	height: auto;
	padding: 10px 30px;
	background-color: transparent;
	border: 1px solid transparent;
	font-size: 18px;
	color: $colorMain;
	margin: 20px;
}
.btnNSB:hover{
	color: $colorSecondary;
	transition: 0.3s;
	cursor: pointer;
}

@import 'components/Assets/loading';
@import 'components/Assets/not-a-404';

@import 'components/NavBar/navbar';

@import 'components/Home/Banners/banner-home';
@import 'components/Home/Intro/intro';

@import 'components/clientes/clientes';

@import 'components/Hacemos/hacemos';
@import 'components/MKTExterno/mkt-externo';
@import 'components/MKTDigital/mkt-digital';

@import 'components/Item/item';
@import 'components/ItemList/item-list';

@import 'components/Contacto/contacto';
@import 'components/ContactoForm/contacto-form';

@import 'components/Obra/obra';

@import 'components/Footer/footer';

@media only screen and (min-width: 1000px){
    article{
		width: 60%;
	}  
}