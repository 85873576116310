.swiper-container {
    width: 100vw;
    height: auto;
    display: flex;
    .swiper-slide {	
        height: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .swiper-pagination {
        display: none;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}