.clientes-card{
    width: 140px;
    height: 140px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img{
        width: 100%;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
    }    
}

@media only screen and (min-width: 1000px){
    .img:hover{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: 0.2s;
    }
} 