section{
    .intro{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 50px 0px;
        article{
            text-align: left;
            .span1{
                background-color: $colorYellow;
                padding: 4px;
                font-weight: 600;
            }
            .span2{
                background-color: $colorMagenta;
                color: #fff;
                padding: 4px;
                font-weight: 600;
            }
            .span3{
                background-color: $colorCyan;
                color: #fff;
                padding: 4px;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .intro{
            h2{
                font-size: 40px;
            }
        }
    }
}