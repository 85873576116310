.loading{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 100px 0px;
	h2{
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 30px;
	}
	img{
		width: 200px;
	}
}