section{
    .hacemos{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .container{
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .bkg-magenta{
            background-color: $colorMagenta;
        }
        .bkg-cyan{
            background-color: $colorCyan;
        }
        .bkg-yellow{
            background-color: $colorYellow;
        }
        .bkg-red{
            background-color: $colorRed;
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .hacemos{
            .container{
                width: 33.33%;
            }
        }
    }
}